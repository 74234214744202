ul {
  padding-left: 0 !important;
}
body {
  font-family: "Roboto,sans-serif";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #555;
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.site-btn {
  background-color: #6d56c1;
  height: 50px;
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 15px;
  min-width: 170px;
  text-align: center;
  display: inline-block;
  color: #fff !important;
  font-weight: 600;
  overflow: hidden;
}

.site-btn img {
  height: 14px;
  margin-top: -5px;
  margin-right: 5px;
}

.section .section__title {
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 42px;
  font-family: Roboto Mono, monospace;
}

nav ul li a:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 2px;
  bottom: 0;
  right: 42px;
  opacity: 0;
  transition: background 0.3s;
  background: #6d56c1;
}

svg {
  width: 20px;
}
svg path {
  fill: white;
}
