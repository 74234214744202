.main-header {
  background-color: #6d56c1;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: relative;
  margin-bottom: 40px;
}

.personal-profile .personal-profile__avatar img {
  border-radius: 5px;
  width: 90%;
  height: 380px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 10px;
  position: relative;
  top: 40px;
}

.personal-profile .personal-profile__name {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 10px;
}

.personal-profile .personal-profile__work {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}

.personal-profile .personal-profile__contacts {
  margin-bottom: 20px;
}

.contact-list {
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto Mono, monospace;
}

.contact-list.contact-list__opacity-titles dt {
  opacity: 0.4;
}
.contact-list dt {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-list dd {
  padding-left: 100px;
  margin-bottom: 15px;
}

.personal-profile {
  color: #fff;
  font-family: Roboto Mono, monospace;
  padding-top: 90px;
}

.contact-list dd a {
    color: #fff;
}

.personal-profile .personal-profile__social a {
  font-size: 24px;
  margin-right: 15px;
  opacity: .4;
  color: #fff!important;
}