.contacts .contacts__form-title,
.footer {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto Mono, monospace";
}

.contacts .contacts__form .form-group {
  margin-bottom: 15px;
  position: relative;
}

.contacts .contacts__form input {
  width: 100%;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto Mono, monospace;
}

.contacts .contacts__form input::placeholder,
.contacts .contacts__form textarea::placeholder {
  color: #fff;
}

.contacts .contacts__form textarea {
  width: 100%;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  padding: 10px;
  height: 115px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto Mono, monospace;
}

.site-btn.site-btn--form {
  width: 100%;
  border: none !important;
  //   box-shadow: 0 1px 29px rgb(0 0 0);
  //   box-shadow: 0 1px 29px rgb(0, 0, 0);
  box-shadow: 0 1px 10px #fff;
  position: relative;
}
