.contacts .contacts__list {
  margin-bottom: 45px;
}

.contact-list {
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto Mono, monospace;
}

.contact-list dt {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-list dd {
  padding-left: 100px;
  margin-bottom: 15px;
}

.contact-list dd a {
  color: #fff;
}
