.contacts .contacts__social {
  font-size: 16px;
  line-height: 24px;
}

.contacts .contacts__social li {
  margin-bottom: 20px;
}

.contacts .contacts__social a {
  color: #fff;
  text-decoration: underline;
  padding: 6px 0;
  transition: all 0.3s;
}
