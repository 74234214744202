.background {
  background-color: #6d56c1;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: relative;
  color: #fff;
}

// .background:before {
//   background: linear-gradient(
//     54deg,
//     rgba(74, 76, 179, 0.95) 34%,
//     hsla(0, 34%, 71%, 0.95)
//   );
//   height: 100%;
//   left: 0;
//   right: 0;
//   position: absolute;
//   content: "";
//   z-index: 0;
// }

.contacts {
  margin-top: 20px;
}
