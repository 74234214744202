.project-card {
  margin-left: 0;
  margin-bottom: 40px;
  transition: box-shadow 0.3s;
  margin-right: 0;
}

.project-card .project-card__img {
  padding-right: 0;
  padding-left: 0;
}

.project-card .project-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-card .project-card__info {
  border: 1px solid #f1f1f1;
  padding: 50px 50px 40px 30px;
}

.project-card .project-card__title {
  font-size: 18px;
  line-height: 30px;
  font-family: Roboto Mono, monospace;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 700;
}

.project-card .project-card__description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 45px;
}

.project-card .project-card__stack {
  font-family: "Roboto Mono, monospace";
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.tags li {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  box-shadow: 0 1px 4px #ddd;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}

.project-card .project-card__link {
  margin-top: 55px;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 20px;
  float: right;
}
