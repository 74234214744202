.post-cards {
  margin-bottom: 40px;
}
.post-cards .post-cards__card {
  transition: all 0.3s;
}

.post-cards .post-cards__img {
  padding-top: 100%;
  position: relative;
}

.post-cards .post-cards__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
}

.post-cards .post-cards__info {
  border: 1px solid #f1f1f1;
  padding: 20px 40px 40px 20px;
}

.post-cards .post-cards__date {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #999;
  margin-bottom: 10px;
}

.post-cards .post-cards_title {
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 700;
  color: #555;
}

.post-cards .post-cards_description {
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin-bottom: 0;
}
