.mobile-menu {
  position: fixed;
  top: 20px;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  text-align: center;
  z-index: 9999;
  transition: all 0.3s ease;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
}

.mobile-menu.active {
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 1;
}

.mobile-menu .mobile-menu__close {
  color: #fff;
  transition: all 0.3s;
  position: absolute;
  top: 50px;
  background-color: transparent;
  border: none;
}

.mobile-menu .mobile-menu__wrapper {
  height: 100%;
  overflow: auto;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto Mono, monospace;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 60px;
}

.mobile-menu .mobile-menu__wrapper ul li {
  list-style: none;
  padding: 6px 0;
}

.mobile-menu .mobile-menu__wrapper ul li a {
  color: #fff;
  display: inline-block;
  padding: 11px;
  position: relative;
}

.mobile-menu .mobile-menu__wrapper ul li a:after {
  background: #fff;
  bottom: 14px;
  right: 1px;
}
