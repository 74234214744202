.resume-list {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}

.resume-list .resume-list_title {
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #999;
  margin-bottom: 40px;
}

.resume-list .resume-list__block {
  border-left: 2px solid #6d56c1;
  padding-left: 25px;
  padding-bottom: 50px;
}

.resume-list .resume-list__block:before {
  width: 12px;
  height: 12px;
  border: 2px solid #6d56c1;
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: #fff;
  left: 7px;
}

.resume-list .resume-list__block-title {
  text-transform: uppercase;
  color: #6d56c1;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto Mono, monospace;
  font-weight: 700;
  padding-bottom: 5px;
}

.resume-list .resume-list__block p {
  margin: 0;
}

// Date
.resume-list .resume-list__block-date {
  font-family: Inconsolata, monospace;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  padding-bottom: 10px;
}

// Progress
.progress-list {
  margin-top: 30px;
}

.progress-list .progress-list__title,
.progress-list p {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.progress-list .progress-list__title {
  font-family: Roboto Mono, monospace;
  letter-spacing: 5px;
  font-weight: 700;
  color: #999;
  margin-bottom: 40px;
}

.progress-list p {
  width: 100%;
  font-family: Inconsolata, monospace;
  margin-bottom: 10px;
}

.progress-list .progress {
  height: 6px;
  margin-bottom: 30px;
}

.progress {
  display: -webkit-box; 
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}


.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width .6s ease;
}

.progress-list .progress .progress-bar {
  background-color: #6d56c1;
}

.progress-list p .progress-list__skill-value {
  float: right;
}