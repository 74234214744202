.menu nav ul {
  li {
    display: inline-block;

    a {
      padding-right: 50px;
      color: #fff;
    }
  }
}

.menu {
  // position: fixed;
  padding: 0;
  height: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 70px;
  transition: all 0.5s;
  background-color: #6d56c1;
}

.menu .menu__wrapper {
  z-index: 1;
  margin-top: 50px;
  transition: all 0.3s;
}

.menu nav {
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto Mono, monospace;
  text-transform: uppercase;
  font-weight: 700;
}

.menu .menu__mobile-button {
  color: #fff;
  opacity: 0.5;
  transition: all 0.3s;
  background-color: transparent;
  border: none;
}
